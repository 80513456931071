<template>
  <div class="driverList">
    <div class="search-header">
      <div>
        <search
          v-model="value"
          show-action
          placeholder="请输入手机号查询"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </search>
      </div>
    </div>
    <div class="list-cont" >
      <template v-if="listData.length>0">
        <div v-for="(item, index) in listData" :key="index" class="card-item" @click="showDetail(item)">
          <div class="name-phone">
            <span>{{item.contact_name}}</span>
            <span>{{item.mobile}}</span>
            <tag plain type="primary">{{item.parent.card.name}}</tag>
          </div>
          <div class="price-cardNum">
            <span>卡号：{{item.card_num}}</span>
            <span>车牌号：{{item.car_num}}</span>
          </div>
          <div class="line"></div>
          <div class="state-cardName">
            <div>
              <Button size="mini" round color="#E10034" plain hairline type="primary" @click.stop="goEdit(item)">修改限制</Button>
              <Button size="mini" round color="#E10034" plain hairline type="primary" @click.stop="unbound(item.id)">解除绑定</Button>
            </div>
            <!-- <span>共享卡</span> -->
            <span>剩余:{{item.balance}}元</span>
          </div>
        </div>
      </template>
      
      <empty description="暂无数据" v-else/>
      <Button plain hairline block color="#999" type="primary" @click="$router.push('/teamCardList')">添加司机</Button>
    </div>
    
    <popup v-model="filtrate" position="right" :style="{ width: '30%', height:'100%'}" >
      <sidebar v-model="activeKey" style="width:100%" v-if="cardList">
        <sidebar-item @click="screenCard" title="全部" />
        <sidebar-item v-for="(item, index) in cardList" :key="index" @click="screenCard" :title="item.card.name" />
      </sidebar>
    </popup>
  </div>
</template>

<script>
import creditshop from "@/api/creditshop"
import { Search, Button,Popup,Sidebar, SidebarItem,Tag,Empty } from 'vant';
export default {
  components: {
    Search,
    Button,
    Popup,
    Sidebar,
    SidebarItem,
    Tag,
    Empty
  },
  data() {
    return {
      value: "",
      active: 0,//切换
      listData: "",// 司机列表
      filtrate: false, // 筛选
      activeKey: 0,
      cardList: "",
      screenid: "",
      moneysort: 0,
      screenName: ""// 筛选id
    }
  },
  watch: {
    value(val) {
      if (!val) {
        this.get_driver_list();
      }
    }
  },
  mounted() {
    this.get_driver_list();
    this.getList();
  },
  methods: {
    // 查找司机
    onSearch() {
      this.screenName = "";
      this.get_driver_list();
    },
    goEdit(item) {
      this.$router.push({
        path: "sendCard",
        query: {
          accountId: item.id
        }
      })
    },
    // 筛选卡类型
    screenCard(idx) {
      console.log(idx);
      if (idx == 0) {
        this.screenName = ""
      } else {
        this.screenName = this.cardList[idx-1].card.id
      }
      this.get_driver_list();
      this.filtrate = false;
    },
    // 卡片列表
    async getList() {
      try {
        const res = await creditshop.send_card_list();
        if (res.code == 200) {
          console.log(res);
          if (res.result.length > 0) {
            this.cardList = res.result;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 解绑
    async unbound(id) {
      this.$dialog
        .confirm({
          title: "解绑",
          message: "是否需要解绑"
        })
        .then(() => {
          try {
            creditshop.del_team_card(id).then(res => {
              if (res.code == 200) {
                this.$toast({
                  message: res.msg
                });
                this.get_driver_list();
              } else {
                this.$toast({
                  message: res.msg
                });
              }
            });
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    showDetail(item) {
      console.log(item)
      this.$router.push({
        path: "/driverdetils",
        query: {
          name: item.contact_name,
          money: item.balance,
          id: item.id
        }
      })
    },
    showFiltrate(name) {
      if (name == 1) {
        this.filtrate = true;
      }
      
    },
    async get_driver_list() {
      try {
        const res = await creditshop.driver_list(this.$route.query.id,this.screenName,this.value,this.moneysort)
        if (res.code == 200) {
          this.listData = res.result;
        }
        console.log(res);
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.driverList {
  height: 100vh;
  overflow: auto;
  background: #F1F5F8;
  .search-header {
    background: linear-gradient(90deg,#e20034cf,#e83022b0);
    >div {
      
      line-height: 40px;
      font-size: 14px;
      height: 50%;
      color: #fff;
    }
    .company-name {
      padding: 0 15px;
    }
  }
  .list-cont {
    padding: 10px;
    .card-item {
      padding: 15px 10px;
      background: #fff;
      border-radius: 4px;
      margin-bottom: 10px;
      .name-phone {
        font-size: 16px;
        font-weight: 500;
        color: #222;
        display: flex;
        align-items: center;
        span {
          margin-right: 10px;
        }
      }
      .price-cardNum {
        margin-top: 6px;
        font-size: 10px;
        color: #a5a5a5;
        span:nth-child(1) {
          margin-right: 20px;
          position: relative;
        }
        span:nth-child(1)::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 100%;
          right: -10px;
          background: #a5a5a5;
        }
      }
      .line {
        width: 100%;
        border-top: 1px dashed #a5a5a5;
        margin: 15px 0;
      }
      .state-cardName {
        display: flex;
        justify-content: space-between;
        color: #E10034;
      }
    }
  }
}
</style>